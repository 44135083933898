// Change IS_PRODUCTION  true false
const IS_PRODUCTION = true;
//const PRODUCTION_BASE_URL = 'https://rvnl.org/RVNL_cms/';
const PRODUCTION_BASE_URL = 'https://staging.rvnl.org/RVNL_cms/';
const DEVELOPMENT_BASE_URL = 'http://localhost/rvnl_stg/RVNL/';
//const BASE_HREF = "https://rvnl.org/";
const BASE_HREF = "https://staging.rvnl.org/";
const Config = {
	ENV: IS_PRODUCTION ? 'production' : 'development',
	production: {
		BASE_URL: PRODUCTION_BASE_URL,
		apiURL: PRODUCTION_BASE_URL + 'frontend/',
		production: IS_PRODUCTION,
		basehref:BASE_HREF
	},
	development: {
		BASE_URL: DEVELOPMENT_BASE_URL,
		apiURL: DEVELOPMENT_BASE_URL + 'frontend/',
		production: IS_PRODUCTION,
		basehref:BASE_HREF
	},
}

export const environment = {
	...Config[Config.ENV],
	
};
